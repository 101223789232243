var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-4"},[_vm._v(" Manage your surveys ")]),_c('p',{staticClass:"tw-text-left tw-text-light-grey"},[_vm._v(" "+_vm._s(_vm.trip && _vm.trip.isPremium ? "You have access to create multiple surveys so you can get the details you need to plan the perfect trip." : "Edit your survey or view results by tapping the survey below. You can edit your survey until a response is submitted.")+" ")])])],1),(_vm.dataLoaded)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._l((_vm.surveyList),function(survey){return _c('div',{key:survey.id,staticClass:"tw-flex tw-justify-between tw-mb-8"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full",on:{"click":function($event){return _vm.$router.push({
              name: 'SurveyResults',
              params: { id: _vm.$route.params.id, surveyId: survey.id }
            })}}},[_c('div',{staticClass:"tw-flex tw-items-start tw-flex-col"},[_c('p',{staticClass:"tw-text-left tw-text-18 tw-font-semibold tw-m-0"},[_vm._v(" "+_vm._s(survey.title)+" ")]),_c('p',{staticClass:"tw-text-light-grey tw-text-xxs tw-my-1"},[_vm._v(" "+_vm._s(survey.totalResponses)+" responses ")]),_c('div',{staticClass:"tw-flex tw-items-center"},[(survey.currentUserHasResponded)?_c('img',{staticClass:"tw-w-5 tw-h-5 tw-mr-1 tw--ml-1",attrs:{"src":require("@/assets/svgs/checkmark-with-background.svg"),"alt":"Your response is in"}}):_vm._e(),_c('span',{staticClass:"tw-text-light-grey tw-text-xxs tw-m-0",class:{
                  'tw-text-j-orange': !survey.currentUserHasResponded
                }},[_vm._v(_vm._s(survey.currentUserHasResponded ? "Your response is in" : "Needs your response"))])])]),_c('v-icon',{staticClass:"tw-text-grey-one"},[_vm._v("mdi-arrow-right")])],1),(_vm.isOwner || _vm.isOrganizer)?_c('v-icon',{staticClass:"tw-ml-2 tw-text-grey-one tw-h-auto",attrs:{"size":"24"},on:{"click":function($event){return _vm.activateDeleteSurveyDialog(survey.id)}}},[_vm._v("mdi-delete")]):_vm._e()],1)}),(
          (!_vm.trip.isPremium && _vm.surveyList.length === 0) ||
          (_vm.trip.isPremium && (_vm.isOwner || _vm.isOrganizer))
        )?_c('router-link',{attrs:{"to":{
          name: 'SurveyCreateRouteForm',
          params: { id: this.$route.params.id }
        }}},[_c('v-btn',{staticClass:"j-btn tw-tracking-normal",class:{
            'j-premium tw-text-white': _vm.trip && _vm.trip.isPremium,
            'tw-bg-dark-green tw-text-white': _vm.trip && !_vm.trip.isPremium
          },attrs:{"x-large":"","rounded":"","block":"","depressed":"","type":"button"}},[_vm._v("Create New Survey")])],1):_vm._e(),(!_vm.trip.isPremium && _vm.surveyList.length > 0)?_c('div',{staticClass:"tw-flex tw-flex-col tw-items-center"},[_c('img',{staticClass:"tw-mt-4 tw-mb-2",attrs:{"src":require("@/assets/svgs/premium/multiple-surveys.svg"),"alt":"Multiple Surveys"}}),_c('h1',{staticClass:"tw-text-22 tw-font-semibold tw-my-2"},[_vm._v(" Create additional surveys ")]),_c('p',{staticClass:"tw-text-light-grey tw-text-center"},[_vm._v(" First class trips get access to create multiple surveys so you can get the details you need to plan the perfect trip. ")]),_c('v-btn',{staticClass:"j-btn j-premium tw-tracking-normal tw-text-white tw-mt-4",attrs:{"x-large":"","rounded":"","block":"","depressed":"","type":"button","to":{ name: 'UpgradeTripRoute' }}},[_vm._v("Upgrade to First Class")])],1):_vm._e()],2)],1):_vm._e(),_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.showDeleteSurvey),callback:function ($$v) {_vm.showDeleteSurvey=$$v},expression:"showDeleteSurvey"}},[_c('v-card',[_c('v-card-title',{staticClass:"tw-font-bebas-neue tw-text-uppercase tw-text-charcoal"},[_vm._v("Delete Survey?")]),_c('v-card-text',{staticClass:"tw-text-base tw-text-left"},[_vm._v("Deleting a survey will remove all survey data. This cannot be undone.")]),_c('v-card-actions',{staticClass:"tw-justify-end"},[_c('button',{staticClass:"btn-tertiary tw-mx-4 tw-tracking-normal",on:{"click":function($event){_vm.deleteSurveyId = null;
            _vm.showDeleteSurvey = false;}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"tw-px-4 tw-tracking-normal",attrs:{"rounded":"","color":"primary"},on:{"click":function($event){return _vm.deleteSurvey()}}},[_vm._v("Delete")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }