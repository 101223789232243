<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h3
          class="tw-text-left tw-text-dark-green tw-font-figtree tw-font-semibold tw-text-22 tw-mb-4"
        >
          Manage your surveys
        </h3>
        <p class="tw-text-left tw-text-light-grey">
          {{
            trip && trip.isPremium
              ? "You have access to create multiple surveys so you can get the details you need to plan the perfect trip."
              : "Edit your survey or view results by tapping the survey below. You can edit your survey until a response is submitted."
          }}
        </p>
      </v-col>
    </v-row>
    <v-row v-if="dataLoaded">
      <v-col cols="12">
        <div
          class="tw-flex tw-justify-between tw-mb-8"
          v-for="survey in surveyList"
          :key="survey.id"
        >
          <div
            class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
            @click="
              $router.push({
                name: 'SurveyResults',
                params: { id: $route.params.id, surveyId: survey.id }
              })
            "
          >
            <div class="tw-flex tw-items-start tw-flex-col">
              <p class="tw-text-left tw-text-18 tw-font-semibold tw-m-0">
                {{ survey.title }}
              </p>
              <p class="tw-text-light-grey tw-text-xxs tw-my-1">
                {{ survey.totalResponses }} responses
              </p>
              <div class="tw-flex tw-items-center">
                <img
                  class="tw-w-5 tw-h-5 tw-mr-1 tw--ml-1"
                  src="@/assets/svgs/checkmark-with-background.svg"
                  alt="Your response is in"
                  v-if="survey.currentUserHasResponded"
                />
                <span
                  class="tw-text-light-grey tw-text-xxs tw-m-0"
                  :class="{
                    'tw-text-j-orange': !survey.currentUserHasResponded
                  }"
                  >{{
                    survey.currentUserHasResponded
                      ? "Your response is in"
                      : "Needs your response"
                  }}</span
                >
              </div>
            </div>
            <v-icon class="tw-text-grey-one">mdi-arrow-right</v-icon>
          </div>
          <v-icon
            size="24"
            class="tw-ml-2 tw-text-grey-one tw-h-auto"
            @click="activateDeleteSurveyDialog(survey.id)"
            v-if="isOwner || isOrganizer"
            >mdi-delete</v-icon
          >
        </div>
        <router-link
          v-if="
            (!trip.isPremium && surveyList.length === 0) ||
            (trip.isPremium && (isOwner || isOrganizer))
          "
          :to="{
            name: 'SurveyCreateRouteForm',
            params: { id: this.$route.params.id }
          }"
        >
          <v-btn
            x-large
            rounded
            block
            depressed
            type="button"
            class="j-btn tw-tracking-normal"
            :class="{
              'j-premium tw-text-white': trip && trip.isPremium,
              'tw-bg-dark-green tw-text-white': trip && !trip.isPremium
            }"
            >Create New Survey</v-btn
          >
        </router-link>
        <div
          class="tw-flex tw-flex-col tw-items-center"
          v-if="!trip.isPremium && surveyList.length > 0"
        >
          <img
            src="@/assets/svgs/premium/multiple-surveys.svg"
            class="tw-mt-4 tw-mb-2"
            alt="Multiple Surveys"
          />
          <h1 class="tw-text-22 tw-font-semibold tw-my-2">
            Create additional surveys
          </h1>
          <p class="tw-text-light-grey tw-text-center">
            First class trips get access to create multiple surveys so you can
            get the details you need to plan the perfect trip.
          </p>

          <v-btn
            x-large
            rounded
            block
            depressed
            type="button"
            class="j-btn j-premium tw-tracking-normal tw-text-white tw-mt-4"
            :to="{ name: 'UpgradeTripRoute' }"
            >Upgrade to First Class</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showDeleteSurvey" max-width="400">
      <v-card>
        <v-card-title
          class="tw-font-bebas-neue tw-text-uppercase tw-text-charcoal"
          >Delete Survey?</v-card-title
        >
        <v-card-text class="tw-text-base tw-text-left"
          >Deleting a survey will remove all survey data. This cannot be
          undone.</v-card-text
        >
        <v-card-actions class="tw-justify-end">
          <button
            class="btn-tertiary tw-mx-4 tw-tracking-normal"
            @click="
              deleteSurveyId = null;
              showDeleteSurvey = false;
            "
          >
            Cancel
          </button>
          <v-btn
            rounded
            color="primary"
            class="tw-px-4 tw-tracking-normal"
            @click="deleteSurvey()"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "SurveyList",
  components: {},
  data() {
    return {
      surveyList: [],
      surveyData: [],
      dataLoaded: false,
      confirmDelete: false,
      deleteSurveyId: null,
      showDeleteSurvey: false
    };
  },

  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    isOwner() {
      return this.$store.state.auth.user.id === this.trip.ownerId;
    },
    isOrganizer() {
      if (
        !this.trip ||
        !this.$store.state.auth.user.trips ||
        this.$store.state.auth.user.trips.length === 0
      )
        return false;
      let currentTrip = this.$store.state.auth.user.trips.find(
        (t) => t.id === this.trip.id
      );
      if (!currentTrip) return false;
      return currentTrip.trip_invite.roles.includes("owner");
    }
  },

  methods: {
    async getData() {
      const surveys = await this.$store.dispatch("survey/find", {
        query: {
          tripId: this.$route.params.id
        }
      });

      this.surveyList = surveys?.data;

      // Update titles if necessary
      this.surveyList.forEach((survey, i) => {
        let t = survey.title;
        if (t.trim() === this.trip.title.trim()) {
          t = `${survey.title} #${i + 1}`;
          this.$store.dispatch("survey/patch", [survey.id, { title: t }]);
        }
      });

      const surveyPromises = this.surveyList.map((survey, i) => {
        return this.getSurveyData(survey.id, i);
      });

      await Promise.all(surveyPromises);
      this.dataLoaded = true;
    },
    async getSurveyData(id, i) {
      let res = await this.$store.dispatch("survey-builder/get", id);
      this.surveyList[i].totalResponses = res.userIdsHaveResponded.length;
      this.surveyList[i].currentUserHasResponded = res.currentUserHasResponded;
    },
    async deleteSurvey() {
      await this.$store.dispatch("survey/remove", this.deleteSurveyId);
      this.$store.commit("survey/clearAll", this.$store.state["survey"]);
      this.showDeleteSurvey = false;
      this.dataLoaded = false;
      this.getData();
    },
    activateDeleteSurveyDialog(id) {
      this.deleteSurveyId = id;
      this.showDeleteSurvey = true;
    }
  },

  async beforeMount() {
    await this.$store.dispatch("trip/get", [
      this.$route.params.id,
      {
        query: {
          include: "destination,users,survey,trip_invitation"
        }
      }
    ]);

    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Trip surveys",
      bgColor: "#203848",
      fontColor: "#E6FFA1",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });

    this.getData();
  }
};
</script>
<style lang="scss">
.v-icon.v-icon::after {
  background-color: transparent !important;
}
</style>
